import '@lmig/lmds-styles/lm-styles.css';
import 'babel-polyfill';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { AppLoader } from './components';
import { App } from './screens';
import * as serviceWorker from './serviceWorker';
import { restoreSidebarState } from './utils';
import localizedStrings from './utils/localizedStrings';
import { store } from './common/store';
import 'react-tridi/dist/index.css';
import './styles/base.scss';
import '@master/css';

// Set the anchor point for any modals displayed throughout the app
Modal.setAppElement('#root');

const { BROWSER_TAB_TITLE } = localizedStrings;

document.title = BROWSER_TAB_TITLE;

const {
  settings: {
    environment: { awsConfig },
  },
} = store.getState();

Amplify.configure(awsConfig);

//  Restore sidebar status
restoreSidebarState();

render(
  <Provider store={store}>
    <>
      <AppLoader />
      <App />
    </>
  </Provider>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept('./screens/App/App.js', () => {
    render();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
